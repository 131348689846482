import React from 'react';
import { sessionTicketSendUrl } from '../src/constants/global';

const SessionTicket = () => {
    window.onload = function() {
	
        //var messageEle = document.getElementById('message');
        
        function receiveMessage(e) {
            
            // Check to make sure that this message came from the correct domain.
            if (e.origin !== sessionTicketSendUrl)
                return;
            
            //console.log(e.data, 'session' );		
            localStorage.setItem('sessionTicket', e.data);
        }
    
        // Setup an event listener that calls receiveMessage() when the window
        // receives a new MessageEvent.
        window.addEventListener('message', receiveMessage);
    }
  return (
    <div>SessionTicket</div>
  )
}

export default SessionTicket