import React, { useState, useEffect } from "react";
import axios from 'axios';
import { awsBaseUrl, playFabTitleId } from '../../constants/global';

import './slot-machine.css';
import Reel from '../Reel/reel';
import Popup from '../Popup/popup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons'

import AudioSlotClick from '../../sounds/slot-button-click.mp3';
import AudioSlotSpin from '../../sounds/slot-spin.mp3';
import AudioSlotWin from '../../sounds/slot-win.mp3';
import AudioSlotBigWin from '../../sounds/slot-big-win.mp3';

const SlotMachine = () => {
    const sessionTicket = localStorage.getItem("sessionTicket");

    const reelCount = 5;
    const betAmount = 100;
    const currency = "HashCoins";
    const _TEST_RUN = false;

    const [isSpinning, setIsSpinning] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [credits, setCredits] = useState(0);
    const [winningText, setWinningText] = useState("Spin to win!");
    const [slotOverlayText, setSlotOverlayText] = useState("WIN!");
    const [winClass, setWinClass] = useState("");

    const [winnningLineClass1, setWinnningLineClass1] = useState("");
    const [winnningLineClass2, setWinnningLineClass2] = useState("");
    const [winnningLineClass3, setWinnningLineClass3] = useState("");
    const [winnningLineClass4, setWinnningLineClass4] = useState("");
    const [winnningLineClass5, setWinnningLineClass5] = useState("");

    const [reel1numbersUI, setReel1numbersUI] = useState([1,2,3,4,5]);
    const [reel2numbersUI, setReel2numbersUI] = useState([6,7,8,9,1]);
    const [reel3numbersUI, setReel3numbersUI] = useState([1,2,3,4,5]);
    const [reel4numbersUI, setReel4numbersUI] = useState([6,7,8,9,1]);
    const [reel5numbersUI, setReel5numbersUI] = useState([1,2,3,4,5]);

    useEffect(() => {
        setReel1numbersUI(getRandomNumbers());
        setReel2numbersUI(getRandomNumbers());
        setReel3numbersUI(getRandomNumbers());
        setReel4numbersUI(getRandomNumbers());
        setReel5numbersUI(getRandomNumbers());

        setHashCoinBalance();
        
        if (_TEST_RUN)
        {
            simulateSpins();
        }
    }, []);

    const setHashCoinBalance = async () => {
        const config = {
            headers: { 'X-Authorization': sessionTicket }
        };
        const response = await axios.post("https://" + playFabTitleId + ".playfabapi.com/Client/GetUserInventory", {}, config);
        console.log(response.data.data.VirtualCurrency.HC);
        setCredits(response.data.data.VirtualCurrency.HC);
    };

    const getRandomNumbers = (lowest = 1, highest = 9) => {
      let numbers = [];
      for (let i = 0; i < 100; i++) {
        numbers.push(Math.floor(Math.random() * (highest - lowest + 1)) + lowest);
      }
      return numbers;
    };

    const spin = () => {
        console.log("spin");
        if (credits >= betAmount) {
            setIsLoading(true);
            hideAllWinningLines();

            const fetchData = async () => {
                const config = {
                  headers: { 'X-Authorization': sessionTicket }
                };
                const response = await axios.get(awsBaseUrl + "/Spin", config);
                console.log(response.data);

                setReel1numbersUI(response.data.reel1numbers);
                setReel2numbersUI(response.data.reel2numbers);
                setReel3numbersUI(response.data.reel3numbers);
                setReel4numbersUI(response.data.reel4numbers);
                setReel5numbersUI(response.data.reel5numbers);

                setIsLoading(false);
                setIsSpinning(true);
                setCredits(credits - betAmount);
                if (!isMuted)
                {
                    let audioSlotClick = new Audio(AudioSlotClick);
                    audioSlotClick.play();

                    // let audioSlotSpin = new Audio(AudioSlotSpin);
                    // audioSlotSpin.volume = 0.4;
                    // audioSlotSpin.play();
                }
            };
            fetchData();
            console.log("data fetched");

        } else {
            alert(`You do not have enough ${currency} to spin.`);
        }
    };

    const hideAllWinningLines = () => { 
        setWinnningLineClass1("");
        setWinnningLineClass2("");
        setWinnningLineClass3("");
        setWinnningLineClass4("");
        setWinnningLineClass5("");
    }

    /// Set winnings here
    const checkWinnings = (reel1numbers, reel2numbers, reel3numbers, reel4numbers, reel5numbers) => { 
        let sumOfWinnings = 0;

        //reel1numbers.length
        for (let i = 0; i < reelCount; i++) {  
            // Get the numbers from each reel
            let reel1 = reel1numbers[i];
            let reel2 = reel2numbers[i];
            let reel3 = reel3numbers[i];
            let reel4 = reel4numbers[i];
            let reel5 = reel5numbers[i];

            //console.log(reel1, reel2, reel3, reel4, reel5);

            if (reel1 === reel2 && reel2 === reel3 && reel3 === reel4 && reel4 === reel5)
            {
                // 5 of a kind
                sumOfWinnings += betAmount * getWinningMultiplier(reel1) * 100;
            }
            else if (reel1 === reel2 && reel2 === reel3 && reel3 === reel4)
            {
                // 4 of a kind
                sumOfWinnings += betAmount * getWinningMultiplier(reel1) * 20;
            }
            else if (reel1 === reel2 && reel2 === reel3)
            {
                // 3 of a kind
                sumOfWinnings += betAmount * getWinningMultiplier(reel1);
            }
            else if (reel1 === reel2)
            {
                // 2 of a kind
                sumOfWinnings += betAmount * getWinningMultiplier(reel1) * 0.25;
            }
            else
            {
                //console.log("You did not win anything.");
            }
            //console.log(sumOfWinnings);

            if (reel1 === reel2)
            {
                // Show winning line
                ShowWinningLine(i);
            }
        }

        return sumOfWinnings;
    };

    /// Show specific winning line and fade out after 1 second
    const ShowWinningLine = (lineNumber) => {
        switch (lineNumber) {
            case 0:
                setWinnningLineClass1("show");
                setTimeout(() => {
                    setWinnningLineClass1("show fade-out");
                }, 1000);
                break;
            case 1:
                setWinnningLineClass2("show");
                setTimeout(() => {
                    setWinnningLineClass2("show fade-out");
                }, 1000);
                break;
            case 2:
                setWinnningLineClass3("show");
                setTimeout(() => {
                    setWinnningLineClass3("show fade-out");
                }, 1000);
                break;
            case 3:
                setWinnningLineClass4("show");
                setTimeout(() => {
                    setWinnningLineClass4("show fade-out");
                }, 1000);
                break;
            case 4:
                setWinnningLineClass5("show");
                setTimeout(() => {
                    setWinnningLineClass5("show fade-out");
                }, 1000);
                break;
        }
    }

    /// Get the multiplier for a specific symbol
    const getWinningMultiplier = (symbolNumber) => { 
        switch (symbolNumber) {
        case 1:
            return 0.6;
        case 2:
            return 0.8;
        case 3:
            return 1;
        case 4:
            return 1.2;
        case 5:
            return 1.4;
        case 6:
            return 2;
        case 7:
            return 3;
        case 8:
            return 4;
        case 9:
            return 8;
        default:
            return 0.6;
        }
    };

    const outputWinningsTable = () => {
        let tableHTML = `<table class="winnings-table">
                            <tr>
                              <th>Symbol</th>
                              <th>5 of a kind</th>
                              <th>4 of a kind</th>
                              <th>3 of a kind</th>
                              <th>2 of a kind</th>
                            </tr>`;
        for (let i = 9; i >= 1; i--) {
          let multiplier = getWinningMultiplier(i);
          tableHTML += `<tr>
                            <td><img src="images/${i}.png" height="100px" alt="${i}"/></td>
                            <td>${betAmount * multiplier * 100}</td>
                            <td>${betAmount * multiplier * 20}</td>
                            <td>${betAmount * multiplier}</td>
                            <td>${betAmount * multiplier * 0.25}</td>
                        </tr>`;
        }
        tableHTML += `</table>`;
        return tableHTML;
    };

    const simulateSpins = () => {
        let totalWager = 0;
        let totalWinnings = 0;
      
        for (let i = 0; i < 1000000; i++) {
          if (credits >= betAmount) {
            let reel1numbers = getRandomNumbers();
            let reel2numbers = getRandomNumbers();
            let reel3numbers = getRandomNumbers();
            let reel4numbers = getRandomNumbers();
            let reel5numbers = getRandomNumbers();
            
            totalWager += betAmount;
            let winnings = checkWinnings(reel1numbers, reel2numbers, reel3numbers, reel4numbers, reel5numbers);
            totalWinnings += winnings;
          } else {
            alert(`You do not have enough ${currency} to spin.`);
          }
        }
      
        console.log(`Total wager: ${totalWager} ${currency}`);
        console.log(`Total winnings: ${totalWinnings} ${currency}`);
        console.log(`RTP: ${(totalWinnings / totalWager) * 100}%`);
    };

    useEffect(() => {
        if (isSpinning)
        {
            setWinningText("Spin to win!");
            setTimeout(() => {
                // Compute winnings
                setIsSpinning(false);
                let winnings = checkWinnings(reel1numbersUI, reel2numbersUI, reel3numbersUI, reel4numbersUI, reel5numbersUI);

                if (winnings > betAmount * 100) {
                    setSlotOverlayText("JACKPOT WIN!");
                    setWinClass("slot-machine__reels__overlay__text--jackpot-win");
                    setTimeout(() => {
                        setWinClass("");
                    }, 5000);
                }
                else if (winnings > betAmount * 20) {
                    setSlotOverlayText("MEGA WIN!");
                    setWinClass("slot-machine__reels__overlay__text--mega-win");
                    setTimeout(() => {
                        setWinClass("");
                    }, 5000);
                }
                else if (winnings > betAmount * 5) {
                    setSlotOverlayText("BIG WIN!");
                    setWinClass("slot-machine__reels__overlay__text--big-win");
                    setTimeout(() => {
                        setWinClass("");
                    }, 3000);
                }
                else if (winnings > 0) {
                    setSlotOverlayText("NICE!");
                    setWinClass("slot-machine__reels__overlay__text--win");
                    setTimeout(() => {
                        setWinClass("");
                    }, 1500);
                }

                if (winnings > 0 && !_TEST_RUN) {
                    setCredits(credits + winnings);
                    setWinningText(`You won ${winnings} ${currency}!`);
                    if (!isMuted)
                    {
                        // Play the winning sound
                        let audioSlotWin;
                        if (winnings > betAmount * 5) {
                            audioSlotWin= new Audio(AudioSlotBigWin);
                        }
                        else {
                            audioSlotWin= new Audio(AudioSlotWin);
                        }
                        audioSlotWin.play();
                    }
                }
            }, 2500);
        }
    }, [isSpinning]);

    return (
        <div className="slot-machine">

            <div className="slot-machine__title">1 Spin = {betAmount} {currency}</div>
            
            <div className="slot-machine__reels">
                <div className="slot-machine__winning-lines">
                    <div className={`slot-machine__winning-line1 ${winnningLineClass1}`}></div>
                    <div className={`slot-machine__winning-line2 ${winnningLineClass2}`}></div>
                    <div className={`slot-machine__winning-line3 ${winnningLineClass3}`}></div>
                    <div className={`slot-machine__winning-line4 ${winnningLineClass4}`}></div>
                    <div className={`slot-machine__winning-line5 ${winnningLineClass5}`}></div>
                </div>
                <Reel reelId={1} rollDuration={500} numbers={reel1numbersUI} isSpinning={isSpinning} isLoading={isLoading} muted={isMuted} />
                <Reel reelId={2} rollDuration={1000} numbers={reel2numbersUI} isSpinning={isSpinning} isLoading={isLoading} muted={isMuted} />
                <Reel reelId={3} rollDuration={1500} numbers={reel3numbersUI} isSpinning={isSpinning} isLoading={isLoading} muted={isMuted} />
                <Reel reelId={4} rollDuration={2000} numbers={reel4numbersUI} isSpinning={isSpinning} isLoading={isLoading} muted={isMuted} />
                <Reel reelId={5} rollDuration={2500} numbers={reel5numbersUI} isSpinning={isSpinning} isLoading={isLoading} muted={isMuted} />
                <div className="slot-machine__reels__overlay">
                    <div className={`slot-machine__reels__overlay__text ${winClass}`}>{slotOverlayText}</div>
                </div>
            </div>

            <div className="slot-machine__credits">{currency}: {credits}</div>
            <div className="slot-machine__instructions">{winningText}</div>

            <div className="slot-machine__controls">
                <Popup htmlTable={outputWinningsTable()} />
                <button className="slot-machine__button" onClick={spin} disabled={(isSpinning || isLoading)}>
                    {(isSpinning || isLoading) ? "Spinning..." : "Spin"}
                </button>
                <button className='slot-machine__button' onClick={() => setIsMuted(!isMuted)} >
                    <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
                </button>
            </div>
        </div>
    );
};

export default SlotMachine;