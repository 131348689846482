import logo from './logo.svg';
import './App.css';
import SlotMachine from './components/SlotMachine/slot-machine';
import SessionTicket from './SessionTicket';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
        <Routes>
          <Route path='/' element={<SlotMachine />} />
          <Route path='SessionTicket' element={<SessionTicket />} />
        </Routes>
      </Router>
    
  );
}

export default App;
