import React, { useState, useEffect } from "react";
import './reel.css';
import AudioReelStop from '../../sounds/slot-reel-stop.mp3';

const Reel = ({ reelId, numbers, isSpinning, isLoading, rollDuration, muted }) => {
    const [reel, setReel] = useState(numbers);
    const [spinClass, setSpinClass] = useState("");
    const [loadingClass, setLoadingClass] = useState("");

    useEffect(() => {
      if (isSpinning) {
        setReel(numbers);
        setSpinClass("spin");

        const timeout = setTimeout(() => {
          setSpinClass("");
          if (!muted) {
            const audioReelStop = new Audio(AudioReelStop);
            audioReelStop.play();
          }
        }, rollDuration);
        return () => clearTimeout(timeout);
      }
    }, [isSpinning]);

    useEffect(() => {
      if (isLoading) {
        setReel(numbers);
        setLoadingClass("loading");
      } else {
        setLoadingClass("");
      }
    }, [isLoading]);

    return <div className="reel">
        {reel.map((item, index) => (
            <div key={index} className={`slot-machine__slot-item ${spinClass} ${loadingClass}`}>
                <img src={`images/${item}.png`} height="100px" alt={item} />
            </div>
        ))}
    </div>;
};

export default Reel;