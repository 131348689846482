import React, { useState, useEffect, useRef  } from 'react';
import './popup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

const TablePopup = ({ htmlTable }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <>
        <button className="slot-machine__button" onClick={() => setIsPopupOpen(true)}>
            <FontAwesomeIcon icon={faTrophy} color="white" />
        </button>
        {isPopupOpen && (
            <div className='popup'>
                <div className='header' style={{textAlign: "center"}}>
                  <button onClick={() => setIsPopupOpen(false)} >X</button>
                </div>
                <div className="slot-machine__winnings-table" dangerouslySetInnerHTML={{ __html: htmlTable }} />
            </div>
        )}
    </>
  );
}

export default TablePopup;